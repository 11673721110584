import React, { useEffect, useContext } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Divider, Drawer, Typography, useMediaQuery } from '@material-ui/core';
import CurrencyDollar from '../../icons/CurrencyDollar';
import AuthContext from '../../contexts/JWTContext';
import UsersIcon from '../../icons/Users';
import QueueIcon from '@material-ui/icons/Queue';
import Logo from '../Logo';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import CustomLogout from './CustomLogout';
import useAuth from '../../hooks/useAuth';
import { capitalize } from 'lodash';

const DashboardSidebar = (props) => {
  const [sections, setSections] = React.useState([]);

  useEffect(() => {
    setSections([
      {
        title: 'Main Lists',
        items: [
          {
            title: 'Purchases',
            path: '/dashboard/purchases',
            icon: <UsersIcon fontSize="small" />,
            roles: ['admin', 'ops', 'doctor', 'sales'],
          },
          {
            title: 'Customers',
            path: '/dashboard/customers',
            icon: <UsersIcon fontSize="small" />,
            roles: ['admin', 'ops', 'doctor', 'sales'],
          },
          {
            title: 'Sales',
            path: '/dashboard/sales',
            icon: <UsersIcon fontSize="small" />,
            roles: ['admin', 'ops', 'sales', 'doctor'],
          },
          {
            title: 'Connect',
            path: '/dashboard/connect',
            icon: <UsersIcon fontSize="small" />,
            roles: ['admin', 'ops', 'doctor', 'sales'],
          },
        ]
      },
      {
        title: 'Clinical Queues',
        items: [
          {
            title: 'Naltrexone Refills',
            path: '/dashboard/customers/upcoming-nax-refills',
            icon: <QueueIcon fontSize="small" />,
            roles: ['admin', 'ops', 'doctor'],
          },
          {
            title: 'Async Consultations',
            path: '/dashboard/async-consultations',
            icon: <QueueIcon fontSize="small" />,
            roles: ['admin', 'doctor', 'ops'],
          },
          {
            title: 'Insurance Consults',
            path: '/dashboard/customers/upcoming-insurance-consults',
            icon: <QueueIcon fontSize="small" />,
            roles: ['admin', 'ops', 'doctor'],
          },
        ]
      },
      {
        title: 'Payments',
        items: [
          {
            title: 'Plans',
            path: '/dashboard/nmi/plans',
            icon: <CurrencyDollar fontSize="small" />,
            roles: ['admin', 'ops', 'sales'],
          },
          {
            title: 'Orders',
            path: '/dashboard/orders',
            icon: <CurrencyDollar fontSize="small" />,
            roles: ['admin', 'ops', 'doctor', 'sales'],
          },
          {
            title: 'Collections',
            path: '/dashboard/collections',
            icon: <CurrencyDollar fontSize="small" />,
            roles: ['admin', 'ops'],
          },
        ]
      },
    ]);
  }, []);

  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { isInitialized } = useContext(AuthContext);
  const { user } = useAuth();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('xs'));
  const userRoles = user?.roles;

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location]);
  if (!isInitialized) {
    return <div>Loading...</div>;
  }

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: 'none',
              xs: 'flex'
            },
            justifyContent: 'center',
            p: 1
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 32,
                width: 32
              }}
            />
          </RouterLink>
        </Box>
        <Box sx={{ p: 1 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 1
            }}
          >
            <Typography
              color="textPrimary"
              variant="subtitle2"
              sx={{ margin: 'auto' }}
            >
              {`${capitalize(user.firstname)} ${capitalize(user.lastname)}`}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 1 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 1
                }
              }}
              {...section}
              items={section.items.filter((item) => (
                !item?.roles || item?.roles?.some((role) => userRoles?.includes(role))
              ))}
            />
          ))}
        </Box>
        <Divider />
        <Box sx={{ ml: 1, my: 1 }}>
          <CustomLogout />
        </Box>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 240
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 240
        }
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
