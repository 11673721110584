import { Link as RouterLink, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  makeStyles,
  useTheme,
  useMediaQuery,
  Box,
  MenuItem,
  Button,
  Menu,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';

import UsersIcon from '../../icons/Users';
import loadingImage from '../../media/ch-white-icon.png';
import CustomLogout from './CustomLogout';
import DrawerComponent from './DrawerComponent';
import { useState } from 'react';
import { ListItemButton } from '@mui/material';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import useAuth from '../../hooks/useAuth';
import QueueIcon from '@material-ui/icons/Queue';
import CurrencyDollar from '../../icons/CurrencyDollar';

const useStyles = makeStyles((theme) => ({
  navlinks: {
    marginLeft: theme.spacing(0),
    display: 'flex',
    flexGrow: 1,
  },
  logo: {
    flexGrow: '1',
    cursor: 'pointer',
  },
  link: {
    textDecoration: 'none',
    color: 'white',
    fontSize: '18px',
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(2),
    '&:hover': {
      borderBottom: '1px solid white',
    },
  },
}));

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none',
  }),
  zIndex: theme.zIndex.drawer + 100,
}));

const DashboardNavbar = (props) => {
  const { onSidebarMobileOpen, ...other } = props;
  const { user } = useAuth();
  const isAsync = user?.is_async;
  const roles = user?.roles;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  // State for dropdown menus
  const [customersDrop, setCustomersDrop] = useState(false);
  const [queuesDrop, setQueuesDrop] = useState(false);
  const [paymentDrop, setPaymentDrop] = useState(false);
  // Boolean flags for menu states
  const openCustomersDrop = Boolean(customersDrop);
  const openQueuesDrop = Boolean(queuesDrop);
  const openPaymentDrop = Boolean(paymentDrop);
  // Menu handlers
  const handleCustomersDrop = (event) => {
    setCustomersDrop(event.currentTarget);
  };

  const handleQueuesDrop = (event) => {
    setQueuesDrop(event.currentTarget);
  };

  const handlePayment = (event) => {
    setPaymentDrop(event.currentTarget);
  };

  const handleCloseCustomers = () => {
    setCustomersDrop(null);
  };

  const handleCloseQueues = () => {
    setQueuesDrop(null);
  };

  const handleClosePayment = () => {
    setPaymentDrop(null);
  };

  // Menu items with role-based access
  const mainListsMenu = [
    {
      title: 'Purchases',
      path: '/dashboard/purchases',
      roles: ['admin', 'ops', 'doctor', 'sales'],
    },
    {
      title: 'Customers',
      path: '/dashboard/customers',
      roles: ['admin', 'ops', 'doctor', 'sales'],
    },
    {
      title: 'Sales',
      path: '/dashboard/sales',
      roles: ['admin', 'ops', 'sales'],
    },
    {
      title: 'Connect',
      path: '/dashboard/connect',
      roles: ['admin', 'ops', 'sales'],
    },
  ];

  const clinicalQueuesMenu = [
    {
      title: 'Naltrexone Refills',
      path: '/dashboard/customers/upcoming-nax-refills',
      roles: ['admin', 'ops', 'doctor'],
    },
    {
      title: 'Async Consultations',
      path: '/dashboard/async-consultations',
      roles: ['admin', 'doctor', 'ops'],
      requiresAsync: true,
    },
    {
      title: 'Insurance Consults',
      path: '/dashboard/customers/upcoming-insurance-consults',
      roles: ['admin', 'ops', 'doctor'],
    },
  ];

  const paymentsMenu = [
    {
      title: 'Plans',
      path: '/dashboard/nmi/plans',
      roles: ['admin'],
    },
    {
      title: 'Orders',
      path: '/dashboard/orders',
      roles: ['admin', 'ops'],
    },
    {
      title: 'Collections',
      path: '/dashboard/collections',
      roles: ['admin', 'ops'],
    },
  ];

  // Filter function for menu items
  const hasAccess = (item) => {
    if (item.requiresAsync && !isAsync) return false;
    return !item.roles || item.roles.some((role) => roles?.includes(role));
  };

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar>
        <Box sx={{ flexGrow: 1 }}>
          <RouterLink
            to="/"
            className={classes.logo}
          >
            <img
              src={loadingImage}
              alt="chooselife"
              height="40"
              width="40"
              display="inline"
            />
          </RouterLink>
        </Box>

        {isMobile ? (
          <DrawerComponent />
        ) : (
          <>
            <div>
              <Button
                id="lists-button"
                aria-controls={openCustomersDrop ? 'lists-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openCustomersDrop ? 'true' : undefined}
                sx={{ color: 'white', margin: 0, padding: 0, marginLeft: 2 }}
                onClick={handleCustomersDrop}
              >
                MAIN LISTS
                {openCustomersDrop ? <ExpandLess /> : <ExpandMore />}
              </Button>
              <Menu
                id="lists-menu"
                anchorEl={customersDrop}
                open={openCustomersDrop}
                onClose={handleCloseCustomers}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                {mainListsMenu
                  .filter(hasAccess)
                  .map((item) => (
                    <MenuItem key={item.path}>
                      <ListItemButton component={Link} to={item.path}>
                        <ListItemIcon>
                          <UsersIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary={item.title} />
                      </ListItemButton>
                    </MenuItem>
                  ))}
              </Menu>

              <Button
                id="queues-button"
                aria-controls={openQueuesDrop ? 'queues-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openQueuesDrop ? 'true' : undefined}
                sx={{ color: 'white', margin: 0, padding: 0, marginLeft: 2 }}
                onClick={handleQueuesDrop}
              >
                CLINICAL QUEUES
                {openQueuesDrop ? <ExpandLess /> : <ExpandMore />}
              </Button>
              <Menu
                id="queues-menu"
                anchorEl={queuesDrop}
                open={openQueuesDrop}
                onClose={handleCloseQueues}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                {clinicalQueuesMenu
                  .filter(hasAccess)
                  .map((item) => (
                    <MenuItem key={item.path}>
                      <ListItemButton component={Link} to={item.path}>
                        <ListItemIcon>
                          <QueueIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary={item.title} />
                      </ListItemButton>
                    </MenuItem>
                  ))}
              </Menu>

              <Button
                id="payments-button"
                aria-controls={openPaymentDrop ? 'payments-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openPaymentDrop ? 'true' : undefined}
                sx={{ color: 'white', margin: 0, padding: 0, marginLeft: 2 }}
                onClick={handlePayment}
              >
                PAYMENTS
                {openPaymentDrop ? <ExpandLess /> : <ExpandMore />}
              </Button>
              <Menu
                id="payments-menu"
                anchorEl={paymentDrop}
                open={openPaymentDrop}
                onClose={handleClosePayment}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                {paymentsMenu
                  .filter((item) => !item.roles || item.roles.some((role) => roles.includes(role)))
                  .map((item) => (
                    <MenuItem key={item.path}>
                      <ListItemButton component={Link} to={item.path}>
                        <ListItemIcon>
                          <CurrencyDollar fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary={item.title} />
                      </ListItemButton>
                    </MenuItem>
                  ))}
              </Menu>
            </div>
            <Box sx={{ ml: 2 }}>
              <CustomLogout />
            </Box>
          </>
        )}
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default DashboardNavbar;
