import React, { useState, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Card,
  Container,
  TextField,
  InputAdornment,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  Typography,
  Grid,
  Chip,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  Divider,
  CircularProgress,
} from '@material-ui/core';
import { DataGridPro } from '@mui/x-data-grid-pro';
import SearchIcon from '../../icons/Search';
import FilterListIcon from '@material-ui/icons/FilterList';
import { customerApi } from '../../api/customerApi';
import { debounce } from 'lodash';
import NotesIcon from '@material-ui/icons/Notes';
import moment from 'moment';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';

import SalesListColumns from '../../utils/columns/SalesListColumns';
import {
  PRODUCT_TYPES,
  FORM_SOURCES,
} from '../../constants';

const SalesList = () => {
  const [sales, setSales] = useState([]);
  const [salesCount, setSalesCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 50,
    page: 0,
  });
  const [openFilters, setOpenFilters] = useState(false);
  const [filters, setFilters] = useState(() => {
    const savedFilters = sessionStorage.getItem('salesListFilters');
    return savedFilters ? JSON.parse(savedFilters) : {
      sortBy: 'createdAt',
      sortDir: 'DESC',
      productType: null,
      formSource: null,
      hasPassedLastAssessment: null,
      state: '',
      hasInsuranceDetails: null,
    };
  });
  const [salesNotesDialog, setSalesNotesDialog] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const [salesNotes, setSalesNotes] = useState([]);
  const [loadingNotes, setLoadingNotes] = useState(false);

  const handleViewSalesNotes = async (lead) => {
    setSelectedLead(lead);
    setSalesNotesDialog(true);
    setLoadingNotes(true);
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      const notes = await customerApi.getSalesNotes(accessToken, lead.id);
      setSalesNotes(notes);
    } catch (error) {
      console.error('Error fetching sales notes:', error);
      toast.error('Failed to fetch sales notes');
    } finally {
      setLoadingNotes(false);
    }
  };
  const columns = SalesListColumns(handleViewSalesNotes);
  const getSales = useCallback(async (page, searchValue) => {
    setLoading(true);
    try {
      const params = {
        offset: paginationModel.pageSize * page,
        limit: paginationModel.pageSize,
        sortBy: filters.sortBy,
        sortDir: filters.sortDir,
        productType: filters.productType ?? undefined,
        formSource: filters.formSource ?? undefined,
        hasPassedLastAssessment: filters.hasPassedLastAssessment !== null ? filters.hasPassedLastAssessment : undefined,
        state: filters.state ?? undefined,
        hasInsuranceDetails: filters.hasInsuranceDetails,
        searchValue: searchValue?.toLowerCase(),
      };

      // Replace this with the actual API call when it's ready
      const data = await customerApi.getAllLeads(params);

      setSales(data.users);
      setSalesCount(data.count);
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  }, [paginationModel.pageSize, filters]);

  const debouncedGetSales = useCallback(
    debounce((newQuery) => {
      setPaginationModel((prevModel) => ({ ...prevModel, page: 0 }));
      getSales(0, newQuery);
    }, 300),
    [getSales]
  );

  useEffect(() => {
    getSales(paginationModel.page, searchQuery);
  }, [getSales, paginationModel.page, filters]);

  useEffect(() => {
    sessionStorage.setItem('salesListFilters', JSON.stringify(filters));
    sessionStorage.setItem('salesListQuery', searchQuery);
  }, [filters, searchQuery]);

  const handleQueryChange = (event) => {
    const newQuery = event.target.value;
    setSearchQuery(newQuery);
    debouncedGetSales(newQuery);
  };

  const handleOpenFilters = () => {
    setOpenFilters(true);
  };

  const handleCloseFilters = () => {
    setOpenFilters(false);
  };

  const handleFilterChange = (filterType, value) => {
    if (filterType === 'hasInsuranceDetails') {
      value = value === '' ? null : value === 'true';
    }
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: value
    }));
  };

  const handleApplyFilters = () => {
    setPaginationModel((prevModel) => ({ ...prevModel, page: 0 }));
    getSales(0, searchQuery);
    handleCloseFilters();
  };

  const handleRemoveFilter = (filterType) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: null
    }));
    setPaginationModel((prevModel) => ({ ...prevModel, page: 0 }));
    getSales(0, searchQuery);
  };

  const handleRemoveAllFilters = () => {
    const defaultFilters = {
      sortBy: 'createdAt',
      sortDir: 'DESC',
      productType: null,
      formSource: null,
      hasPassedLastAssessment: null,
      state: '',
      hasInsuranceDetails: null,
    };
    setFilters(defaultFilters);
    setSearchQuery('');
    sessionStorage.setItem('salesListFilters', JSON.stringify(defaultFilters));
    sessionStorage.setItem('salesListQuery', '');
    setPaginationModel((prevModel) => ({ ...prevModel, page: 0 }));
    getSales(0, '');
  };

  const renderActiveFilters = () => {
    const activeFilters = Object.entries(filters).filter(([key, value]) => value !== null && value !== '' && key !== 'sortBy' && key !== 'sortDir');

    if (activeFilters.length === 0 && !searchQuery) return null;

    return (
      <Box sx={{ mt: 2, mb: 2 }}>
        {activeFilters.map(([key, value]) => (
          <Chip
            key={key}
            label={`${key}: ${value?.toString() ?? ''}`}
            onDelete={() => handleRemoveFilter(key)}
            color="primary"
            variant="outlined"
            sx={{ mr: 1, mb: 1 }}
          />
        ))}
        {searchQuery && (
          <Chip
            label={`Search: ${searchQuery}`}
            onDelete={() => setSearchQuery('')}
            color="primary"
            variant="outlined"
            sx={{ mr: 1, mb: 1 }}
          />
        )}
        <Chip
          label="Clear All Filters"
          onDelete={handleRemoveAllFilters}
          color="secondary"
          variant="outlined"
          sx={{ mr: 1, mb: 1 }}
        />
      </Box>
    );
  };

  const handleCloseSalesNotes = () => {
    setSalesNotesDialog(false);
    setSelectedLead(null);
    setSalesNotes([]);
  };

  const handleCreateSalesNote = async (values, { resetForm }) => {
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      await customerApi.createSalesNote(accessToken, selectedLead.id, values.note);
      const notes = await customerApi.getSalesNotes(accessToken, selectedLead.id);
      setSalesNotes(notes);
      resetForm();
      toast.success('Sales note created successfully');
      // Refresh the sales list to show updated notes
      getSales(paginationModel.page, searchQuery);
    } catch (error) {
      console.error('Error creating sales note:', error);
      toast.error('Failed to create sales note');
    }
  };

  return (
    <>
      <Helmet>
        <title>Dashboard: Sales List | Choose Ketamine</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  )
                }}
                onChange={handleQueryChange}
                placeholder="Search by email, first or last name"
                variant="outlined"
                value={searchQuery}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<FilterListIcon />}
                onClick={handleOpenFilters}
              >
                Filters
              </Button>
            </Grid>
          </Grid>
          {renderActiveFilters()}
          <Box sx={{ mt: 3 }}>
            <Card>
              <Box sx={{ height: 'calc(100vh - 200px)', width: '100%' }}>
                <DataGridPro
                  rows={sales}
                  columns={columns}
                  rowCount={salesCount}
                  loading={loading}
                  pageSizeOptions={[25, 50, 100]}
                  paginationModel={paginationModel}
                  paginationMode="server"
                  onPaginationModelChange={(newModel) => {
                    setPaginationModel(newModel);
                    getSales(newModel.page, searchQuery);
                  }}
                  sortingMode="server"
                  onSortModelChange={(model) => {
                    if (model.length > 0) {
                      handleFilterChange('sortBy', model[0].field);
                      handleFilterChange('sortDir', model[0].sort?.toUpperCase());
                      setPaginationModel((prevModel) => ({ ...prevModel, page: 0 }));
                      getSales(0, searchQuery);
                    }
                  }}
                  disableSelectionOnClick
                  pagination
                />
              </Box>
            </Card>
          </Box>
        </Container>
      </Box>
      <Dialog open={openFilters} onClose={handleCloseFilters} maxWidth="md" fullWidth>
        <DialogTitle>Filters</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <Typography variant="subtitle1">Product Type</Typography>
                <Select
                  value={filters.productType ?? ''}
                  onChange={(e) => handleFilterChange('productType', e.target.value)}
                >
                  <MenuItem value="">All Product Types</MenuItem>
                  {PRODUCT_TYPES.map((type) => (
                    <MenuItem key={type} value={type}>{type}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <Typography variant="subtitle1">Form Source</Typography>
                <Select
                  value={filters.formSource ?? ''}
                  onChange={(e) => handleFilterChange('formSource', e.target.value)}
                >
                  <MenuItem value="">All Sources</MenuItem>
                  {FORM_SOURCES.map((source) => (
                    <MenuItem key={source} value={source}>{source}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <Typography variant="subtitle1">Has Passed Last Assessment</Typography>
                <Select
                  value={filters.hasPassedLastAssessment ?? ''}
                  onChange={(e) => handleFilterChange('hasPassedLastAssessment', e.target.value)}
                >
                  <MenuItem value="">All Results</MenuItem>
                  <MenuItem value>Pass</MenuItem>
                  <MenuItem value={false}>Fail</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <Typography variant="subtitle1">State</Typography>
                <Select
                  value={filters.state ?? ''}
                  onChange={(e) => handleFilterChange('state', e.target.value)}
                >
                  <MenuItem value="">All States</MenuItem>
                  <MenuItem value="AL">Alabama</MenuItem>
                  <MenuItem value="AK">Alaska</MenuItem>
                  <MenuItem value="AZ">Arizona</MenuItem>
                  <MenuItem value="AR">Arkansas</MenuItem>
                  <MenuItem value="CA">California</MenuItem>
                  <MenuItem value="CO">Colorado</MenuItem>
                  <MenuItem value="CT">Connecticut</MenuItem>
                  <MenuItem value="DE">Delaware</MenuItem>
                  <MenuItem value="FL">Florida</MenuItem>
                  <MenuItem value="GA">Georgia</MenuItem>
                  <MenuItem value="HI">Hawaii</MenuItem>
                  <MenuItem value="ID">Idaho</MenuItem>
                  <MenuItem value="IL">Illinois</MenuItem>
                  <MenuItem value="IN">Indiana</MenuItem>
                  <MenuItem value="IA">Iowa</MenuItem>
                  <MenuItem value="KS">Kansas</MenuItem>
                  <MenuItem value="KY">Kentucky</MenuItem>
                  <MenuItem value="LA">Louisiana</MenuItem>
                  <MenuItem value="ME">Maine</MenuItem>
                  <MenuItem value="MD">Maryland</MenuItem>
                  <MenuItem value="MA">Massachusetts</MenuItem>
                  <MenuItem value="MI">Michigan</MenuItem>
                  <MenuItem value="MN">Minnesota</MenuItem>
                  <MenuItem value="MS">Mississippi</MenuItem>
                  <MenuItem value="MO">Missouri</MenuItem>
                  <MenuItem value="MT">Montana</MenuItem>
                  <MenuItem value="NE">Nebraska</MenuItem>
                  <MenuItem value="NV">Nevada</MenuItem>
                  <MenuItem value="NH">New Hampshire</MenuItem>
                  <MenuItem value="NJ">New Jersey</MenuItem>
                  <MenuItem value="NM">New Mexico</MenuItem>
                  <MenuItem value="NY">New York</MenuItem>
                  <MenuItem value="NC">North Carolina</MenuItem>
                  <MenuItem value="ND">North Dakota</MenuItem>
                  <MenuItem value="OH">Ohio</MenuItem>
                  <MenuItem value="OK">Oklahoma</MenuItem>
                  <MenuItem value="OR">Oregon</MenuItem>
                  <MenuItem value="PA">Pennsylvania</MenuItem>
                  <MenuItem value="RI">Rhode Island</MenuItem>
                  <MenuItem value="SC">South Carolina</MenuItem>
                  <MenuItem value="SD">South Dakota</MenuItem>
                  <MenuItem value="TN">Tennessee</MenuItem>
                  <MenuItem value="TX">Texas</MenuItem>
                  <MenuItem value="UT">Utah</MenuItem>
                  <MenuItem value="VT">Vermont</MenuItem>
                  <MenuItem value="VA">Virginia</MenuItem>
                  <MenuItem value="WA">Washington</MenuItem>
                  <MenuItem value="WV">West Virginia</MenuItem>
                  <MenuItem value="WI">Wisconsin</MenuItem>
                  <MenuItem value="WY">Wyoming</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <Typography variant="subtitle1">Has Insurance Details</Typography>
                <Select
                  value={filters.hasInsuranceDetails === null ? '' : filters.hasInsuranceDetails.toString()}
                  onChange={(e) => handleFilterChange('hasInsuranceDetails', e.target.value)}
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFilters} color="primary">
            Cancel
          </Button>
          <Button onClick={handleApplyFilters} color="primary" variant="contained">
            Apply Filters
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={salesNotesDialog}
        onClose={handleCloseSalesNotes}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Box display="flex" alignItems="center" gap={1}>
            <NotesIcon />
            <Typography variant="h6">
              Sales Notes for
              {' '}
              {selectedLead?.firstname}
              {' '}
              {selectedLead?.lastname}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box mb={3}>
            <Formik
              initialValues={{ note: '' }}
              validationSchema={Yup.object({
                note: Yup.string().required('Note is required')
              })}
              onSubmit={handleCreateSalesNote}
            >
              {({ isSubmitting, touched, errors }) => (
                <Form>
                  <Box display="flex" gap={2}>
                    <Field
                      name="note"
                      as={TextField}
                      fullWidth
                      label="New Sales Note"
                      variant="outlined"
                      multiline
                      rows={2}
                      error={touched.note && Boolean(errors.note)}
                      helperText={touched.note && errors.note}
                    />
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      sx={{ minWidth: '120px' }}
                    >
                      Add Note
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>

          <Divider sx={{ my: 2 }} />

          {loadingNotes && (
            <Box display="flex" justifyContent="center" p={3}>
              <CircularProgress />
            </Box>
          )}
          {!loadingNotes && salesNotes.length > 0 && (
            <List>
              {salesNotes.map((note, index) => (
                <React.Fragment key={note.id}>
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary={(
                        <Typography variant="subtitle1">
                          {note.note}
                        </Typography>
                      )}
                      secondary={(
                        <Box mt={1}>
                          <Typography variant="caption" color="textSecondary">
                            By
                            {' '}
                            {note.created_by}
                            {' '}
                            on
                            {' '}
                            {moment(note.created_at).format('MMM D, YYYY h:mm A')}
                          </Typography>
                        </Box>
                      )}
                    />
                  </ListItem>
                  {index < salesNotes.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
          )}
          {!loadingNotes && salesNotes.length === 0 && (
            <Typography variant="body1" color="textSecondary" align="center">
              No sales notes found for this lead.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSalesNotes} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SalesList;
